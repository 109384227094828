
import {
  IonPage,
  IonHeader,
  IonContent,
  onIonViewDidEnter,
  onIonViewWillEnter,
} from "@ionic/vue";
import OptionMenu from "@/components/optionMenu.vue";
import Header from "@/components/header.vue";
import { getDicSimpleList } from "@/service/member";
import { getQualifications } from "@/service/home";
import { ref, watch } from "vue";
import { openImages } from "@/utils/alert";

export default {
  name: "Qualifications",
  components: { OptionMenu, Header, IonHeader, IonContent, IonPage },
  setup() {
    const data = {
      menus: ref([]) as any,
      zizhidata: ref([]) as any,
      checkedMenus: ref(""),
    };

    const methods = {};

    const getZizhiTypeTeamSettingDetail = async () => {
      const result: any = await getDicSimpleList({
        enCode: "ZizhiType",
      });
      console.log(result);
      data.menus.value = result.map(({ itemname, itemvalue }: any) => ({
        label: itemname,
        value: itemvalue,
      }));
      data.checkedMenus.value = data.menus.value[0].value;
    };

    const getQualificationsDetail = async () => {
      const result = await getQualifications({
        typeid: data.checkedMenus.value,
      });
      data.zizhidata.value = result;
      console.log(data.zizhidata);
    };

    //进入页面并且渲染完页面后触发，每次进入页面都触发
    onIonViewDidEnter(async () => {
      await getZizhiTypeTeamSettingDetail();
      getQualificationsDetail();
    });

    watch(data.checkedMenus, () => {
      getQualificationsDetail();
    });
    //进入页面后触发，每次进入页面都触发
    onIonViewWillEnter(() => {
      console.log("onIonViewWillEnter!");
    });

    return { ...data, methods, openImages };
  },
};
