import request from '@/utils/http.service';
import { homeApi } from '@/utils/api.config';

export function getSlides(data: any) {
  return request({
    url: homeApi.slides,
    method: 'POST',
    data
  }).then((results) => results)
}

export function getQualifications(data: any) {
  return request({
    url: homeApi.qualifications,
    method: 'POST',
    data
  }).then((results) => results)
}

export function getZizhiForHomeList(data: any) {
  return request({
    url: homeApi.getzizhiforhomelist,
    method: 'POST',
    data
  }).then((results) => results)
}

export function getHomeDataInfo(data: any) {
  return request({
    url: homeApi.gethomedatainfo,
    method: 'POST',
    data
  }).then((results) => results)
}

export function feedbackcommit(data: any) {
  return request({
    url: homeApi.feedbackcommit,
    method: 'POST',
    data
  }).then(() => true)
}