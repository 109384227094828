import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3933c194"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "qualification-list" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  class: "title",
  style: {"font-size":"13px","font-weight":"500"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_OptionMenu = _resolveComponent("OptionMenu")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_Header, { title: "资质证书" }),
          _createVNode(_component_OptionMenu, {
            options: _ctx.menus,
            modelValue: _ctx.checkedMenus,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedMenus) = $event)),
            type: "slider"
          }, null, 8, ["options", "modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.zizhidata, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "qualification-item",
                key: index,
                onClick: ($event: any) => ($setup.openImages(_ctx.zizhidata.map(({ zizhiImgId }) => zizhiImgId),index))
              }, [
                _createElementVNode("img", {
                  src: item.zizhiImgId
                }, null, 8, _hoisted_3),
                _createElementVNode("h2", _hoisted_4, _toDisplayString(item.zizhiName), 1)
              ], 8, _hoisted_2))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}